<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="connection-request">
    <generate-prompt-steps
      :message="text"
      :generate.sync="generateMessage"
      :action-id="actionId"
      class="connection-request__generate-prompt-step"
      :class="{'connection-request__generate-prompt-step_open' : showInputFeature && isTextIncludes}"
    />
    <div class="flex-row left connection-request__switchers flex-gap-8">
      <div class="connection-request__switcher">
        <div class="flex-gap-4 flex">
          <default-switcher
            size="s"
            :is-checked="isTextIncludes"
            @change="isTextIncludes = !isTextIncludes"
          />
          <span
            class="text_s"
            :class="{'color_secondary': !isTextIncludes}"
          >
            {{ $t('modals.connectionRequest.switchers.includeTextMessage') }}
          </span>
        </div>
      </div>
    </div>
    <info-section
      v-if="description"
      class="mb-15"
      :info="description"
    />
    <error-section :error="messageError" />
    <div
      class="connection-request__textarea-container"
      @mouseenter="showTemplateMenu"
      @mouseleave="isShowTemplateMenu = false"
    >
      <textarea
        ref="messageInputRef"
        v-model="text"
        :readonly="readOnly"
        :placeholder="$t('placeholders.typeYourMessage')"
        class="connection-request__textarea"
        :disabled="!isTextIncludes"
        @input="handleMessageInput"
      />
      <span
        v-if="isTextIncludes"
        class="connection-request__counter text_xs color_tertiary"
      >
        {{ counterString }}
      </span>
      <default-button
        v-if="isTextIncludes && tierFeatures.statuses?.ai_assistant_in_campaigns?.exist"
        color="ai-purple-bg"
        form="square"
        size="m"
        :title="!showInputFeature ? $t('actions.showAi') : $t('actions.hideAi')"
        icon-name="bx bxs-magic-wand"
        class="connection-request__show-generate-button"
        @action="handleShowAiPrompt"
      />
      <template-unlink-menu
        v-show="isShowTemplateMenu"
        class="connection-request__template-menu"
        :class="{'connection-request__template-menu_active': isShowTemplateMenu}"
        :template="template"
        @unlink-template="unlinkTemplate"
        @edit-template="editTemplate"
      />
    </div>
    <div class="connection-request__switcher connection-request__switcher_down">
      <default-switcher
        size="s"
        class="left"
        :is-checked="isWaitAccept"
        @change="$emit('update:is-wait-accept', !isWaitAccept)"
      />
      <span
        class="text_s"
        :class="{'color_secondary': !isWaitAccept}"
      >
        {{ $t('modals.connectionRequest.switchers.waitForRequestToBeAccepted') }}
      </span>
    </div>
    <step-menu
      :brand="brand"
      :disabled="!isTextIncludes"
      :video="video"
      :template="template"
      :message="text"
      type="li_connect"
      :is-ai-message-enabled="isAiMessageEnabled"
      :manual-task-settings="manualTaskSettings"
      :is-need-manual-approve="isNeedManualApprove"
      @update:is-ai-message-enabled="$emit('update:is-ai-message-enabled', $event)"
      @update:manual-task-settings="$emit('update:manual-task-settings', $event)"
      @update:is-need-manual-approve="$emit('update:is-need-manual-approve', $event)"
      @add-practice="addPractice"
      @add-template-to-area="addTemplate"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
      @unlink-template="unlinkTemplate"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
    />
  </div>
</template>
<script>
  import { ref } from 'vue';
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import { templatesMixin } from '@/mixins/templatesMixin.js';
  import resizableMixin from '@/mixins/resizableMixin.js';
  import GeneratePromptSteps from '@/components/base/GeneratePrompt/GeneratePromptSteps.vue';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import InfoSection from '@/components/updateWorkflow/performAction/editActions/InfoSection.vue';
  import ErrorSection from '@/components/updateWorkflow/performAction/editActions/ErrorSection.vue';
  import TemplateUnlinkMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplateUnlinkMenu';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { useTextareaResize } from '@/use/useTextareaResize';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { tierFeatures } from '@/data/tiersFeatures';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ConnectionRequest',
    components: {
      TemplateUnlinkMenu,
      StepMenu,
      DefaultSwitcher,
      GeneratePromptSteps,
      DefaultButton,
      InfoSection,
      ErrorSection,
    },
    mixins: [addTemplateMixin, templatesMixin, resizableMixin],
    props: {
      brand: {
        type: String,
        default: '',
      },
      isWaitAccept: {
        type: Boolean,
        default: true,
      },
      message: {
        type: String,
        default: '',
      },
      status: {
        type: Boolean,
        default: false,
      },
      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      actionId: {
        type: String,
        default: '',
      },
      video: {
        type: Object,
        default: null,
      },
      template: {
        type: Object,
        default: null,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      messageLength: {
        type: Number,
        default: 0,
      },
      isAiMessageEnabled: {
        type: Boolean,
        default: false,
      },
      isNeedManualApprove: {
        type: Boolean,
        default: false,
      },
      manualTaskSettings: {
        type: Object,
        default: () => ({}),
      },
    },
    setup () {
      const messageInputRef = ref(null);

      const { resizeTextarea, resetTextareaHeight } = useTextareaResize(messageInputRef);

      return {
        messageInputRef,
        resizeTextarea,
        resetTextareaHeight,
      };
    },
    data () {
      return {
        text: this.message,
        isTextIncludes: false,
        templateSpace: [],
        isShowTemplateMenu: false,
        generateMessage: '',
        showInputFeature: false,
        maxMessageLength: 200,
        tierFeatures,
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
        space: 'account/space',
      }),
      description () {
        if (!this.isTextIncludes) {
          return null;
        }
        let text = '';
        if (this.selectedCredential.is_no_text_connect_allowed) {
          text = this.$t('campaignsPage.editor.steps.sendConnectionRequest.noTextEnabled');
        } else {
          text = this.$t('campaignsPage.editor.steps.sendConnectionRequest.noTextDisabled');
        }
        return {
          text,
          icon: 'bx bxs-error',
          link: {
            text: this.$tc('common.setting', 2),
            to: {
              name: 'limits',
              query: { selectedCred: this.selectedCredential._id },
            },
          },
        };
      },
      counterString () {
        return `${this.avgMessageLength}/${this.maxMessageLength}`;
      },
      avgMessageLength () {
        return this.getAvgTextLength(this.text);
      },
    },
    watch: {
      message () {
        this.text = this.message;
        if (!this.status && this.message.length > 0) {
          this.$emit('update:status', true);
        }
      },
      text () {
        this.$emit('update:message', this.text, this.avgMessageLength);
        this.$emit('update:message-length', this.avgMessageLength);
      },
      template () {
        if (this.template?.text_template) {
          this.text = this.template.text_template;
        }
        this.resetTextareaHeight();
      },
      isTextIncludes () {
        if (!this.isTextIncludes) {
          this.text = '';
        }
      },
      generateMessage (value) {
        this.text = value;
      },
    },
    created () {
      this.$emit('update:message-length', this.avgMessageLength);
      this.$emit('update:max-message-length', this.maxMessageLength);
      if (this.message) {
        this.$emit('update:status', true);
      }
    },
    mounted () {
      if (this.message) {
        this.isTextIncludes = true;
      }
      this.resizeTextarea();
    },
    methods: {
      handleShowAiPrompt () {
        useUpgradeModal(() => {
          this.showInputFeature = !this.showInputFeature;
        }, 'ai_assistant_in_campaigns');
      },
      handleMessageInput () {
        this.resizeTextarea();
      },
      notifyAboutMessageLengthLimit () {
        this.$noty.error(
          this.$t('notifications.messageCanNotBeLongerThan', {
            count: this.maxMessageLength,
          })
        );
      },
      handleStatusChange () {
        if (this.status) {
          this.text = '';
        }
        this.$emit('update:status', !this.status);
      },
      showTemplateMenu () {
        if (this.readOnly) {
          this.isShowTemplateMenu = true;
        }
      },
      addTemplate (template, space) {
        if (this.avgMessageLength + space + 1 <= this.maxMessageLength) {
          this.text = this.addTemplateToArea(this.text, template, 'messageInputRef');
        } else {
          this.notifyAboutMessageLengthLimit();
        }
      },
      unlinkTemplate () {
        this.isShowTemplateMenu = false;
        this.$emit('unlink-template');
      },
      editTemplate (template) {
        this.isShowTemplateMenu = false;
        this.$emit('edit-template', template);
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.text = item.message;
      },
    },
  };
</script>
<style lang="scss">
.connection-request {
  &__switchers{
    padding: 12px 16px;
  }

  &__generate-prompt-step {
    transition: 0.2s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    max-height: 0;

    &_open {
      padding: 12px 16px;

      opacity: 1;
      visibility: visible;
      max-height: 100vh;
    }
  }

  &__switcher {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    &_down {
      padding: 16px;
    }
  }
  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
    &_active {
      opacity: 1;
    }
  }

  &__textarea-container {
    max-height: 200px;
    overflow: auto;
    padding: 8px 16px;
    background: var(--neutral-50);
    border-top: 1px solid rgba(10, 32, 51, 0.06);
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(2, 1fr);
  }

  &__textarea {
    grid-row: 1/-1;
  }

  &__counter,
  &__show-generate-button {
    position: sticky;
    justify-self: end;
  }

  &__counter {
    top: 0;
    align-self: start;
  }

  &__show-generate-button {
    bottom: 0;
    align-self: end;
  }

  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    min-height: 160px;
    background: inherit;
  }
}
</style>
