import { setTooltipPosition, getAppearanceClass, getPositionClass, getWidthValue } from './utils';
import { ObjectID } from 'bson';
export const TooltipDirective = {
    _id: new ObjectID().toHexString(),
    inserted: (element, binding) => {
        // #region Tooltip setup
        const tooltipText = typeof binding.value === 'string' ? binding.value : binding.value?.content;
        const tooltipElement = document.createElement('span');
        tooltipElement.id = 'v-tooltip-' + TooltipDirective._id;
        tooltipElement.innerHTML = tooltipText;
        tooltipElement.classList.add('v-tooltip');
        tooltipElement.classList.add(getAppearanceClass(binding.value?.appearance));
        tooltipElement.classList.add(...getPositionClass(binding.value.position));
        binding.value?.textAlign !== undefined &&
            tooltipElement.style.setProperty('--text-align', getWidthValue(binding.value?.textAlign));
        binding.value?.minWidth !== undefined &&
            tooltipElement.style.setProperty('--min-width', getWidthValue(binding.value?.minWidth));
        binding.value?.width !== undefined &&
            tooltipElement.style.setProperty('--width', getWidthValue(binding.value?.width));
        binding.value?.maxWidth !== undefined &&
            tooltipElement.style.setProperty('--max-width', getWidthValue(binding.value?.maxWidth));
        // #endregion
        // #region Mouse events handlers
        element.addEventListener('mouseenter', () => {
            tooltipElement.classList.remove('v-tooltip_disappearing');
            setTooltipPosition(element, tooltipElement, binding.value.position);
            document.body.append(tooltipElement);
            window.addEventListener('scroll', onScrollEvent, true);
        }, { passive: true });
        element.addEventListener('mouseleave', () => {
            tooltipElement.classList.add('v-tooltip_disappearing');
            window.removeEventListener('scroll', onScrollEvent, true);
        }, { passive: true });
        // #endregion
        // #region Animation handlers
        tooltipElement.onanimationend = (ev) => {
            if (ev.animationName === 'v-tooltip-disappear') {
                tooltipElement.remove();
                tooltipElement.classList.remove('v-tooltip_disappearing');
            }
        };
        // #endregion
        // #region Helpers
        function onScrollEvent() {
            setTooltipPosition(element, tooltipElement, binding.value.position);
        }
        // #endregion
    },
    unbind: () => {
        document.getElementById('v-tooltip-' + TooltipDirective._id)?.remove();
    },
};
