<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="workflow-step-item"
    :class="{ 'workflow-step_error-item': errorMessage }"
    @click="showAction = true"
  >
    <header
      class="workflow-step-item__header"
    >
      <div class="workflow-step-item__info">
        <span class="workflow-step-item__text text_s">
          <i
            class="bx"
            :class="icon"
          />
          {{ title }}
        </span>
        <div class="workflow-step-item__step">
          <span
            class="workflow-step-item__text text_s color_secondary margin-left"
          >
            {{ $t('campaignsPage.editor.steps.step.header.stepCount', { count: index + 1 }) }}
          </span>
          <i
            class="workflow-step-item__delete bx bx-x color_danger flex center"
            @click.stop="deleteAction"
          />
        </div>
      </div>
    </header>
    <div class="workflow-step-item__main">
      <info-item :info="info" />
      <action-step-tags :action="action" />
      <span
        v-if="errorMessage"
        class="text_s color_danger"
      >{{ errorMessage }}</span>
      <footer class="workflow-step-item__footer">
        <delay-counter
          :delay_days.sync="delay_days"
          :delay_hours.sync="delay_hours"
        />
      </footer>
    </div>
    <workflow-step-edit-modal
      :name="action.type + index"
      :email-threading="emailThreading"
      :index="index"
      :title="title"
      :is-email-only="isEmailOnly"
      :show.sync="showAction"
      :action="action"
      @update-action="updateAction"
      @update-error="updateError"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { capitalize, capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin.js';
  import InfoItem from '@/components/updateWorkflow/performAction/InfoItem.vue';
  import DelayCounter from '@/components/updateWorkflow/performAction/DelayCounter.vue';
  import WorkflowStepEditModal from '@/components/updateWorkflow/performAction/WorkflowStepEditModal.vue';
  import ActionStepTags from '@/components/updateWorkflow/performAction/editActions/ActionStepTags.vue';

  export default {
    name: 'PerformActionItem',
    components: {
      WorkflowStepEditModal,
      DelayCounter,
      InfoItem,
      ActionStepTags,
    },
    mixins: [capitalizeFilterMixin],
    props: {
      action: {
        type: Object,
        default: () => ({}),
      },
      index: {
        type: Number,
        default: 0,
      },
      isEmailOnly: {
        type: Boolean,
        default: false,
      },
      emailThreading: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        showAction: false,
        errorMessage: '',
        delay_days: this.action.delay_days,
        delay_hours: this.action.delay_hours,
        info: null,
        modalKey: this.action.type + this.index,
        modalProps: {
          width: '800px',
          height: 'auto',
          modalStyle: { maxHeight: '95vh', overflow: 'visible' },
          name: `step${this.index}`,
          showButtons: false,
        },
        title: '',
        icon: 'MessageSquareIcon',
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
        selectedCredential: 'credentials/selectedCredential',
        integrationsList: 'integrations/integrationsList',
      }),
      checkIntegration () {
        return this.integrationsList.find(i => i._id === this.action.integration_id);
      },
      hasStatistics () {
        return this.$route.name === 'edit-campaign' && this.action.type === 'send_email' && this.action.statistics;
      },
    },
    watch: {
      action () {
        this.delay_days = this.action.delay_days;
        this.delay_hours = this.action.delay_hours;
        this.setDefault();
      },
      delay_days (value) {
        this.$emit('update-action', this.index, { delay_days: value });
      },
      delay_hours (value) {
        this.$emit('update-action', this.index, { delay_hours: value });
      },
    },
    mounted () {
      this.setDefault();
    },
    methods: {
      toggleModal (show) {
        const name = this.action.type + this.index;
        show
          ? this.$modal.show(name)
          : this.$modal.hide(name);
        if (!show) {
          this.modalKey = `${this.modalKey} ${new Date().getTime()}`;
        }
      },
      updateAction (update) {
        this.$emit('update-action', this.index, update);
      },
      updateError (update) {
        this.errorMessage = update;
      },
      setDefault () {
        switch (this.action.type) {
        case 'li_actions':
          this.title = this.$t('campaignsPage.editor.steps.performAction.title');
          this.icon = 'bxs-user-detail';
          this.info = { actions: this.action.actions };
          break;
        case 'li_send_message':
          this.title = this.$t('campaignsPage.editor.steps.sendMessage.title');
          this.icon = 'bx-message-detail';
          this.info = { message: this.action.text_template };
          break;
        case 'li_send_inmail':
          this.title = this.$t('campaignsPage.editor.steps.sendInMail.title');
          this.icon = 'bx-edit';
          this.info = {
            subject: this.action.subject_template,
            message: this.action.text_template,
          };
          break;
        case 'send_email':
          this.title = this.$t('campaignsPage.editor.steps.sendEmail.title');
          this.icon = 'bx-mail-send';
          this.errorMessage = this.checkIntegration ? '' : this.$t('campaignsPage.editor.steps.sendEmail.errorMessage');
          this.info = { email_type: this.action.email_type };
          break;
        case 'li_connect':
          this.title = this.$t('campaignsPage.editor.steps.sendConnectionRequest.title');
          this.icon = 'bx-user-plus';
          this.info = {
            message: this.action.text_template || this.$t('campaignsPage.editor.steps.sendConnectionRequest.infoMessage'),
          };
          break;
        case 'li_connect_by_email':
          this.title = this.$t('campaignsPage.editor.steps.sendConnectionRequestByEmail.title');
          this.icon = 'bx-user-pin';
          this.info = { message: this.$t('campaignsPage.editor.steps.sendConnectionRequestByEmail.infoMessage') };
          break;
        case 'enrich_profile':
          this.title = this.$t('campaignsPage.editor.steps.enrichProfile.title');
          this.icon = 'bx-diamond';
          this.info = {
            message: this.$t('campaignsPage.editor.steps.enrichProfile.infoMessage', {
              name: this.$options.filters.capitalize(this.user.config.name),
            }),
          };
          break;
        case 'send_webhook':
          this.title = this.$t('campaignsPage.editor.steps.sendWebhook.title');
          this.icon = 'bx-universal-access';
          this.errorMessage = this.action.webhook_link ? '' : this.$t('campaignsPage.editor.steps.sendWebhook.errorMessage');
          this.info = {
            message: this.$t('campaignsPage.editor.steps.sendWebhook.title', {
              brand: this.$options.filters.capitalize(this.user.brand),
            }),
          };
          break;
        case 'li_invite_event':
          this.title = 'Invite to Event';
          this.icon = 'bx-calendar-event';
          this.errorMessage = this.action?.event_link ? '' : this.$t('campaignsPage.editor.steps.inviteEvent.errorMessage');
          this.info = { message: this.action?.event_link || this.$t('campaignsPage.editor.steps.inviteEvent.infoMessage') };
          break;
        case 'push_to_crm':
          this.title = this.$t('actions.pushToCrm');
          this.icon = 'bx-line-chart';
          this.info = {
            message: `Push prospect data to ${this.action?.crm_platform === 'default'
              ? (capitalize(this.$config.brand) + 'CRM')
              : capitalize(this.action?.crm_platform || '...SELECT PLATFORM')}`,
          };
          break;
        case 'manual_task':
          this.title = this.$t('campaignsPage.editor.steps.manualTask.title');
          this.icon = 'bx-calendar-check';
          this.info = {
            subject: this.action.manual_task_settings.title,
            message: this.action.manual_task_settings.note,
            emptyState: 'No description',
          };
          break;
        case 'manual_call':
          this.title = this.$t('campaignsPage.editor.steps.manualCall.title');
          this.icon = 'bx-phone';
          this.info = {
            message: this.action.manual_task_settings.note,
            emptyState: 'No call note',
          };
          break;
        case 'send_sms':
          this.title = this.$t('campaignsPage.editor.steps.sendSms.title');
          this.icon = 'bx-message-square-dots';
          this.info = {
            message: this.action.text_template,
            emptyState: 'No message',
          };
          break;
        default:
          break;
        }
        Object.assign(this.modalProps, {
          title: this.title,
        });
      },
      changeActions (type) {
        const actions = JSON.parse(JSON.stringify(this.action.actions));
        if (actions.includes(type)) {
          const typeIndex = actions.indexOf(type);
          actions.splice(typeIndex, 1);
        } else {
          actions.push(type);
        }
        this.updateAction({ actions });
      },

      deleteAction () {
        this.$emit('delete-action', this.index);
      },
    },
  };
</script>

<style lang="scss">
.workflow-step-item {
  transition-duration: 1s;
  cursor: pointer;
  padding: 0 4px 4px;
  border-radius: 12px;
  border-top: none;
  box-shadow: 0px 2px 8px rgba(10, 32, 51, 0.12);
  background: var(--neutral-100);
  overflow: hidden;
  &:hover {
    .workflow-step-item__step {
      margin-right: 0px;
    }
    .workflow-step-item__delete {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer;
      box-shadow: 0px 4px 12px rgba(10, 32, 51, 0.16);
    }
  }
  &__header {
    border-radius: 8px 8px 0px 0px;
    padding: 8px 4px 12px;
    margin-bottom: -8px;
  }
  &__info {
    display: flex;
    gap: 4px;
    justify-content: space-between;
  }
  &__step {
    display: flex;
    gap: 4px;
    margin-right: -20px;
    align-items: center;
    transition: 0.5s;
  }
  &__delete {
    background: var(--table-bg);
    border-radius: 100%;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none;
    &:hover {
      opacity: 0.7;
    }
  }
  &__main {
    background: var(--table-bg);
    border-radius: 8px;
    display: flex;
    height: 192px;
    padding: 16px;
    justify-content: space-between;
    flex-flow: column;
    gap: 8px;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px
  }
}
</style>
