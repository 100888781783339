<template>
  <section class="ai-message-menu">
    <feature-upgrade-placeholder
      v-if="tierFeatures.statuses?.icebreakers?.upgrade && !isAiMessageEnabled"
      class="margin-left margin-right"
      icon="bxs-magic-wand"
      feature-name="AI Message"
      feature-id="icebreakers"
      description="Unlock the ability to create unique messages for each prospect based on the above messages"
    />

    <template v-else>
      <article class="ai-message-menu__switcher p-2">
        <header class="flex-row flex-justify-content-between">
          <h2 class="text_s text_medium">
            {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.aiMessage.aiMessageSwitcher.title') }}
          </h2>

          <default-switcher
            :is-checked="isAiMessageEnabled"
            size="xs"
            @change="handleAiMessageToggle"
          />
        </header>

        <main>
          <p class="text_xs color_tertiary mt-05">
            {{ $t('campaignsPage.editor.steps.modals.stepEdit.menu.aiMessage.aiMessageSwitcher.info') }}
          </p>
        </main>
      </article>

      <ai-message-menu-info :prompt-example="message" />
    </template>
  </section>
</template>

<script setup>
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import AiMessageMenuInfo from '@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/AiMessageMenuInfo.vue';
  import FeatureUpgradePlaceholder from '@/components/upgradeWalls/FeatureUpgradePlaceholder.vue';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { tierFeatures } from '@/data/tiersFeatures';

  const props = defineProps({
    isAiMessageEnabled: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
      default: '',
    },
  });

  const emit = defineEmits(['update:is-ai-message-enabled']);

  const handleAiMessageToggle = () => {
    if (props.isAiMessageEnabled) {
      emit('update:is-ai-message-enabled', !props.isAiMessageEnabled);
    } else {
      useUpgradeModal(() => {
        emit('update:is-ai-message-enabled', !props.isAiMessageEnabled);
      }, 'icebreakers');
    }
  };
</script>

<style lang="scss">
.ai-message-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &__switcher {
    min-width: 320px;
    max-width: 320px;
    border-radius: 8px;
    border: 1px solid var(--neutral-300);
  }
}
</style>
