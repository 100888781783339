var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-menu"},[_c('div',{staticClass:"step-menu__container"},_vm._l((_vm.menuButtonsFiltered),function(button){return _c('div',{key:button._id,staticClass:"step-menu__item",class:{ 'step-menu__item_active': _vm.chosenMenu === button._id },on:{"click":function($event){return _vm.chooseMenu(button._id)}}},[_c('figure',{staticClass:"relative"},[_c('i',{staticClass:"step-menu__icon bx",class:[
            button.icon,
            { 'step-menu__icon_active': _vm.chosenMenu === button._id },
            { 'step-menu__icon_active-ai': _vm.chosenMenu === 'AiMessageMenu' && _vm.chosenMenu === button._id },
          ]}),(button.isBeta)?_c('span',{staticClass:"step-menu__beta-icon color_white icon_size_10 flex-row-centered"},[_vm._v(" ß ")]):_vm._e(),(_vm.hasStep(button))?_c('span',{staticClass:"step-menu__on-icon color_white icon_size_10 flex-row-centered"},[_c('img',{attrs:{"src":require("@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/assets/icon-on.svg"),"alt":"icon-on"}})]):_vm._e()]),_c('span',{staticClass:"step-menu__text step-menu__text_absolute color_tertiary text_xs",class:[
          { 'color_primary': _vm.chosenMenu === button._id },
          {'color_purple': _vm.chosenMenu === 'AiMessageMenu' && _vm.chosenMenu === button._id }
        ]},[_vm._v(" "+_vm._s(button.name)+" ")])])}),0),_c(_vm.chosenMenu,{tag:"component",attrs:{"video":_vm.video,"template":_vm.template,"message":_vm.message,"subject":_vm.subject,"type":_vm.type,"disabled":!!_vm.template?._id,"is-ai-message-enabled":_vm.isAiMessageEnabled,"is-li-approve-enabled":_vm.isLiApproveEnabled,"manual-task-settings":_vm.manualTaskSettings,"is-need-manual-approve":_vm.isNeedManualApprove,"email-signature":_vm.emailSignature},on:{"update:is-ai-message-enabled":function($event){return _vm.$emit('update:is-ai-message-enabled', $event)},"update:manual-task-settings":function($event){return _vm.$emit('update:manual-task-settings', $event)},"update:is-need-manual-approve":function($event){return _vm.$emit('update:is-need-manual-approve', $event)},"add-template-to-area":_vm.addTemplateToArea,"add-practice":_vm.addPractice,"choose-video":function($event){return _vm.$emit('choose-video')},"choose-template":function($event){return _vm.$emit('choose-template')},"unlink-template":function($event){return _vm.$emit('unlink-template')},"remove-video":_vm.removeVideo,"insert-video":_vm.insertVideo}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }