<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    ref="resizeElement"
    class="generate-prompt-inbox"
  >
    <default-input-with-button
      :text-input="inputPrompt"
      :buttons-group-data="buttonsGroupData"
      :message="message"
      :placeholder="$t('placeholders.typeYourPromptInbox')"
      :is-loading="isLoading"
      @get-option="getSwitchPrompt"
      @select-option="setActiveMenuOptions"
      @action="generateText"
      @update:text-input="onTextInputUpdate"
    />

    <div class="mt-1 generate-prompt-inbox__options">
      <default-button
        v-for="option in activeMenuOptions"
        :key="option.name"
        size="s"
        :is-disabled="isLoading"
        :title="option.name"
        color="transparent-gr"
        form="rectangle"
        @action="chooseOption(option.description)"
      />
    </div>
  </div>
</template>

<script setup>
  // GeneratePromptSteps
  import { ref, onBeforeUnmount, onMounted, watch } from 'vue';
  import DefaultInputWithButton from '@/components/base/uiKit.v3/DefaultInputWithButton.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useI18n } from '@/i18n';
  import { useSnack } from '@/lib/useSnack';
  import { generatePrompt } from '@/api/AIMethods';
  import { useSocketIo } from '@/use/socketIo';

  const { sockets, setResponseTimeoutError, resetTimeout } = useSocketIo();
  const { $snack } = useSnack();
  const { t } = useI18n();

  const emit = defineEmits(['update:generate']);
  const props = defineProps({
    message: {
      type: String,
      default: '',
    },
    actionId: {
      type: String,
      default: '',
    },
  });

  // #region Input
  const inputPrompt = ref('');

  function onTextInputUpdate (prompt) {
    inputPrompt.value = prompt;
  }
  // #endregion

  const isLoading = ref(false);

  const buttonsGroupData = ref([
    {
      value: 'refine',
      name: t('campaignsPage.editor.steps.modals.stepEdit.buttons.refinePrompt'),
      loadingName: 'Refining...',
      icon: {
        name: 'bxs-magic-wand',
        color: 'color_tertiary',
      },
    },
    {
      value: 'recreate',
      name: t('campaignsPage.editor.steps.modals.stepEdit.buttons.recreatePrompt'),
      loadingName: 'Recreating...',
      icon: {
        name: 'bx-revision',
        color: 'color_tertiary',
      },
    },
  ]);

  const activeMode = ref(null);

  const setActiveMenuOptions = (value) => {
    if (value === 'refine') {
      activeMode.value = 'refine';
      activeMenuOptions.value = refineOptions;
    } else if (value === 'recreate') {
      activeMode.value = 'recreate';
      activeMenuOptions.value = [];
    }
  };

  const refineOptions = [
    {
      name: '🤏 ' + t('aiPrompt.options.shorten.name'),
      description: t('aiPrompt.options.shorten.goal'),
    },
    {
      name: '☝️ ' + t('aiPrompt.options.elaborate.name'),
      description: t('aiPrompt.options.elaborate.goal'),
    },
    {
      name: '👨‍💼 ' + t('aiPrompt.options.professionalTone.name'),
      description: t('aiPrompt.options.professionalTone.goal'),
    },
    {
      name: '🤝 ' + t('aiPrompt.options.convincingTone.name'),
      description: t('aiPrompt.options.convincingTone.goal'),
    },
    {
      name: '👾 ' + t('aiPrompt.options.addJoke.name'),
      description: t('aiPrompt.options.addJoke.goal'),
    },
  ];

  const activeMenuOptions = ref([]);

  watch(() => props.message, (newVal) => {
    if (!newVal) {
      activeMode.value = null;
      activeMenuOptions.value = [];
    } else if (activeMode.value !== 'recreate') {
      activeMenuOptions.value = refineOptions;
    }
  }, {
    immediate: true,
  });

  const chooseOption = async (text) => {
    inputPrompt.value = text;
    await generateText();
  };

  const handleGeneratePrompt = async (userPrompt, actionId, message = '') => {
    if (!userPrompt) return;
    isLoading.value = true;
    try {
      await generatePrompt(userPrompt, actionId, message);
      handleResponseTimeoutError();
    } catch (e) {
      $snack.error(e.message);
    }
  };

  const generateText = async () => {
    await handleGeneratePrompt(inputPrompt.value, props.actionId);
  };

  const refinePrompt = async () => {
    await handleGeneratePrompt(inputPrompt.value, props.actionId, props.message);
  };

  const getSwitchPrompt = async (type) => {
    switch (type?.value) {
    case 'refine':
      await refinePrompt();
      return;
    default:
      await generateText();
    }
  };

  const generatedResponse = ref(null);

  const handleResponseTimeoutError = () => {
    setResponseTimeoutError(
      () => {
        isLoading.value = false;
      }
    );
  };

  onMounted(async () => {
    sockets.subscribe('generated_data', async (response) => {
      const res = JSON.parse(response);
      if (res?.request_id !== props.actionId) return;
      resetTimeout();
      isLoading.value = false;
      inputPrompt.value = '';
      generatedResponse.value = res.payload?.message;
      emit('update:generate', res.payload?.message);
    });
  });

  onBeforeUnmount(() => {
    sockets.unsubscribe('generated_data');
  });
</script>
