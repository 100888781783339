<template>
  <section class="info p-2 background_neutral_50">
    <header class="mb-1">
      <h4 class="text_xs text_medium color_tertiary">
        How AI-powered messages work?
      </h4>
    </header>

    <main class="info__content flex-row flex-gap-8">
      <div class="info__step">
        <div class="info__card">
          <p class="info__card-text">
            {{ promptExample }}
          </p>
        </div>

        <span class="info__card-caption">
          Sourcing your message
        </span>
      </div>

      <i class="bx bx-right-arrow-alt icon_size_20 color_tertiary" />

      <div class="info__step">
        <div class="info__card info__card_round flex-column-centered info__card_round-ai">
          <i
            class="bx bxs-magic-wand color_purple icon_size_20 "
          />
        </div>

        <span class="info__card-caption info__card-caption_mw">
          Collecting data about the prospect
        </span>
      </div>

      <i class="bx bx-right-arrow-alt icon_size_20 color_tertiary" />

      <div class="info__step">
        <div class="info__persons-wrapper flex-row flex-gap-8">
          <div
            v-for="person in personsCards"
            :key="person.name"
            class="info__card info__card_person"
          >
            <div class="flex mb-05">
              <img
                class="uikit-avatar__image info__card-avatar"
                size="xxs"
                :src="person.avatarUrl"
              >

              <span class="flex-column ml-05">
                <span class="info__card-name">
                  {{ person.name }}
                </span>

                <span class="info__card-job-title">
                  {{ person.jobTite }}
                </span>
              </span>
            </div>

            <p class="info__card-text">
              {{ person.message }}
            </p>
          </div>
        </div>

        <span class="info__card-caption">
          Generating unique messages for every prospect
        </span>
      </div>
    </main>
  </section>
</template>

<script setup>
  defineProps({
    promptExample: {
      type: String,
      default: '',
    },
  });
  const personsCards = [
    {
      avatarUrl: require('@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/assets/jade-gaines-avatar.png'),
      name: 'Jade Gaines',
      jobTite: 'CXO at Netflix',
      message: 'Greetings, Jade! 🎬\nNetflix\'s impact on the cinematic landscape is unparalleled. I\'ve been exploring an avenue where artistry meets contemporary fashion. Have you considered integrating themed merchandise that aligns with Netflix\'s',
    },
    {
      avatarUrl: require('@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/assets/emerson-steele-avatar.png'),
      name: 'Emerson Steele',
      jobTite: 'CXO at Duolingo',
      message: 'Hey, Emerson!\nLooks like we both like golfing 🏌🏽‍♂️ It\'s amazing how small is the world. I was surfing your website and saw that you guys are hiring again',
    },
    {
      avatarUrl: require('@/components/updateWorkflow/performAction/menuFiles/aiMessageMenu/assets/billy-fry-avatar.png'),
      name: 'Billy Fry',
      jobTite: 'COX at Cookpad',
      message: 'Hey Billy! 🍳\nCookpad has truly revolutionized the culinary journey for many. Ever thought of spicing things up with a dash of fashion? Envision shirts that not only echo iconic recipes but also tell a story of flavors and culture',
    },
  ];
</script>

<style lang="scss">
.ai-message-menu {
  .info {
    border-radius: 8px;

    &__content {
      display: grid;
      grid-template-columns: 160px min-content 116px min-content 1fr;
    }

    &__step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    &__persons-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &__card {
      background-color: var(--color-surface-0);
      border-radius: 8px;
      padding: 8px;
      box-shadow: 0px 1px 5px 0px rgba(35, 44, 96, 0.12);

      &_round {
        border-radius: 99px;
        width: max-content;

        &-ai {
          padding: 14px;
        }
      }

      &_person {
        .info__card-text {
          -webkit-line-clamp: 4;
        }
      }

      &-avatar {
        min-width: 13px;
        max-width: 13px;
        min-height: 13px;
        max-height: 13px;
      }

      &-name,
      &-job-title {
        line-height: 12px;
        font-size: 10px;
      }

      &-job-title {
        color: var(--color-text-subdued);
      }

      &-text {
        font-size: 10px;
        line-height: 12px;
        display: -webkit-box;
        max-width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &-caption {
        font-size: 10px;
        line-height: 12px;
        color: var(--color-text-subdued);
        text-align: center;

        &_mw {
          max-width: 95px;
        }
      }
    }
  }
}
</style>
